import styled from "styled-components";
import Skeleton from "../../components/ui/Skeleton";
import { CourseCardLoader } from "../../components/cards/CourseCard";
import { useCache } from "../../caching/caches";
import { breakPoints } from "../../utils/config";
import MusicEntryCard from "../../components/cards/MusicEntryCard";

const StyledMusicPage = styled.div`
  padding-bottom: 20rem;
  ${breakPoints.mobile} {
    .cards-container {
      grid-template-columns: 1fr;
    }
  }
`;

const MusicPage = () => {
  const { loading, state: entries } = useCache("music");

  return (
    <StyledMusicPage className="content">
      <h1 className="mtop2">Musik</h1>
      <div className="cards-container">
        {loading ? (
          <Skeleton
            identifier="music"
            quantity={5}
            Component={CourseCardLoader}
          />
        ) : entries.length ? (
          entries.map((entry) => (
            <MusicEntryCard key={entry._id} entry={entry} />
          ))
        ) : (
          <p>Inga musikfiler hittades</p>
        )}
      </div>
    </StyledMusicPage>
  );
};

export default MusicPage;
