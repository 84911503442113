import styled from "styled-components";
import { measurments, shadows } from "../../utils/config";
import Button from "../../sharedUI/Button";
import { audioUrl } from "../../utils/pdfUrl";

const StyledMusicEntryCard = styled.div`
  height: 100%;
  background: white;
  border-radius: ${measurments.borderRadius}px;
  box-shadow: ${shadows[2]};
  .text-container {
    padding: 1.5rem;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    gap: 1rem;

    .text-inner {
      flex-grow: 1;
    }
    audio {
      width: 100%;
    }
    .button-container {
      margin-top: auto;
      margin-left: auto;
      display: flex;
      gap: 0.5rem;
      align-items: center;
      button {
        white-space: nowrap;
      }
    }
  }
`;

const MusicEntryCard = ({ entry }) => {
  return (
    <StyledMusicEntryCard>
      <div className="text-container">
        <div className="text-inner">
          <p className="h3 m0">{entry.title}</p>
        </div>
        <audio controls src={audioUrl(entry.file)} />
        <div className="button-container">
          <a
            href={audioUrl(entry.file)}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button btnType="neutral">Öppna</Button>
          </a>
          <a
            href={audioUrl(entry.file, true)}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button>Ladda ner</Button>
          </a>
        </div>
      </div>
    </StyledMusicEntryCard>
  );
};

export default MusicEntryCard;
