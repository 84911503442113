import { DOServer } from "./config";

export const pdfUrl = (file, download) => {
  if (!file || !file.name || !file.uuid) return "#";
  if (download)
    return `${DOServer}files-download/pdf/${file.uuid}/${file.name}`;
  return `${DOServer}${file.uuid}/${file.name}`;
};

export const audioUrl = (file, download) => {
  if (!file || !file.name || !file.uuid) return "#";
  if (download)
    return `${DOServer}files-download/audio/${file.uuid}/${file.name}`;
  return `${DOServer}${file.uuid}/${file.name}`;
};
